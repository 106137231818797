import {
  IconWithBackgroundColor,
  IconWithBackgroundSize,
} from '@/components/ui/IconWithBackground/typedefs';
import styles from './IconWithBackground.module.scss';

export const ICON_WITH_BACKGROUND_STYLES_BY_COLOR: Record<
  IconWithBackgroundColor, string
> = {
  [IconWithBackgroundColor.Red]: styles.iconRed,
  [IconWithBackgroundColor.Yellow]: styles.iconYellow,
  [IconWithBackgroundColor.Green]: styles.iconGreen,
  [IconWithBackgroundColor.Blue]: styles.iconBlue,
  [IconWithBackgroundColor.Purple]: styles.iconPurple,
  [IconWithBackgroundColor.Gray]: styles.iconGray,
  [IconWithBackgroundColor.DarkGray]: styles.iconDarkGray,
  [IconWithBackgroundColor.None]: '',
  [IconWithBackgroundColor.Pink]: styles.iconPink,
  [IconWithBackgroundColor.Danger]: styles.iconDanger,
};

export const ICON_WITH_BACKGROUND_SIZES: Record<
  IconWithBackgroundSize, string
> = {
  [IconWithBackgroundSize.Small]: styles.iconSizeSmall,
  [IconWithBackgroundSize.Medium]: styles.iconSizeMedium,
  [IconWithBackgroundSize.XXLarge]: styles.iconSizeXXLarge,
};
