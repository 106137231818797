export enum IconWithBackgroundColor {
  Red = 'red',
  Green = 'green',
  Blue = 'blue',
  Yellow = 'yellow',
  Purple = 'purple',
  Gray = 'gray',
  DarkGray = 'dark-gray',
  None = 'none',
  Pink = 'pink',
  Danger = 'danger',
}

export enum IconWithBackgroundSize {
  Small = 'small',
  Medium = 'medium',
  XXLarge = 'xxlarge',
}
