import React, { FC } from 'react';
import { cn } from '@/lib';
import styles from '@/components/ui/IconEmojies/IconEmojiBase/IconEmojiBase.module.scss';

interface Props {
  className?: string;
  dataQa?: string;
}

export const IconEmojiBase: FC<Props> = (props) => {
  const {
    className,
    dataQa = 'icon-emoji-base',
    children,
  } = props;

  return (
    <div className={cn(styles.iconBaseStyles, className, 'emoji')} data-qa={dataQa}>
      {children}
    </div>
  );
};
