import { KeyboardKey } from '@/constants/keyboard';

export abstract class ComponentHarness {
  abstract dataQa: string;

  getElement() {
    return cy.get(this.makeSelector(this.dataQa));
  }

  getElementByDataQa(dataQa: string) {
    return cy.get(this.makeSelector(dataQa));
  }

  getElementByDataQaAndText(dataQa: string, text: string) {
    return cy.get(this.makeSelector(dataQa)).contains(text);
  }

  makeSelector(dataQA: string): string {
    return `[data-qa="${dataQA}"]`;
  }

  getNestedElement(dataQa: string) {
    return this.getElement().find(this.makeSelector(dataQa));
  }

  getNestedByAlt(alt: string) {
    return this.getElement().find(`[alt="${alt}"]`);
  }

  getNestedElementById(id: string) {
    return this.getElement().find(`[id="${id}"]`);
  }

  getNestedByText(text: string) {
    return this.getElement().contains(text);
  }

  click() {
    return this.getElement().click();
  }

  hover() {
    return this.getElement().trigger('mouseover');
  }

  pressKeyboardKeyWithModifier(
    key: KeyboardKey,
    isMac: boolean,
  ) {
    const eventOptions: Partial<KeyboardEventInit> = {
      key,
      code: `Key${key.toUpperCase()}`,
      bubbles: true,
      metaKey: isMac,
      ctrlKey: !isMac,
    };

    cy.document().trigger('keydown', eventOptions);
  }

  assertExists() {
    this.getElement().should('exist');
  }

  assertNotExist() {
    this.getElement().should('not.exist');
  }

  assertVisible() {
    this.getElement().should('be.visible');
  }
}
